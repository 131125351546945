/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef, useState } from 'react';
import { useStickyBox } from 'react-sticky-box';
import { Scrollspy } from '@makotot/ghostui';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, Community, Layout, Modal, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Incubators';
  const description =
    'Njano offers a set of customizable tools that will help you run your incubator operations effectively';
  return <SEO title={title} description={description} location={location} />;
};

const IncubatorsPage = () => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const stickyRef = useStickyBox({ offsetTop: 120, offsetBottom: 20 });

  const [showModal, setShowModal] = useState(false);

  return (
    <Layout logo={false} dark={false}>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:px-[8.75rem] space-y-16">
        <div className="lg:col-span-1 lg:pr-16 flex-row items-start justify-center col-span-2">
          <StaticImage
            className="mb-9"
            src="../images/logo-black.png"
            alt="Njano.org"
            formats={['auto', 'webp', 'avif']}
            placeholder="dominantColor"
            layout="fixed"
            width={328}
          />
          <p className="mb-3 font-kanit text-5xl font-extralight leading-[3.75rem] text-primary lg:mr-12 lg:mb-6">
            Our best features for your incubator
          </p>
          <p className="mt-12 mb-14 font-content text-[2.25rem] font-bold leading-10">
            Njano offers a set of customizable tools that will help you run your incubator operations effectively.
          </p>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            className="!block mx-auto lg:mx-0 lg:inline"
          >
            Get started
          </Button>
        </div>
        <div className="lg:col-span-1 flex items-center justify-center col-span-2">
          <StaticImage
            className="max-w-xl"
            src="../images/header-incubation.png"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="dominantColor"
            layout="constrained"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 p-4 lg:py-[5.25rem] lg:px-[8.75rem]">
        <div className="items-center col-span-1">
          <h2 className="font-heading text-primary lg:text-6xl mb-8 text-4xl font-bold leading-normal text-center">
            Dashboard
          </h2>
          <p className="mb-8 text-center font-content text-[1.625rem] font-bold lg:mx-32">
            All-in-one Dashboard! Start your day with a dynamic and thorough overview of your space. The dashboard
            allows you to track and visualize all your important data instantly.
          </p>
        </div>
      </div>
      <div className="pb-9 lg:pl-8 xl:pl-24 lg:pr-0 px-4">
        <div className="lg:grid-cols-4 grid grid-cols-2">
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#E9F0FF] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/internal-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Calendar
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                The Njano calendar is a user-friendly feature that gathers all previous or upcoming events, and bookings
                in place with the option of adding and removing ones at his convenience.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#FFE7AA] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/community-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Community Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                Your team and space users can easily communicate through private messaging, social circles, and feed
                posts.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#FFE7AA] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/reporting-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={112}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Reporting Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                Now, you can easily build a thorough and detailed report with all your information at hand.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#E9F0FF] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/incubation-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Internal Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                This is your go-to feature to run your daily operations. Here, you can access the user-base, events,
                manage room booking and much more!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:mt-32 lg:px-[8.75rem]">
        <Scrollspy sectionRefs={sectionRefs}>
          {({ currentElementIndexInViewport }) => (
            <>
              <div className="lg:col-span-1 lg:block hidden col-span-2">
                <aside ref={stickyRef}>
                  <div className="pt-8">
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-primary ${
                        currentElementIndexInViewport !== 0 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#1')}
                    >
                      Internal Management
                    </h4>
                    {currentElementIndexInViewport === 0 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#1"
                        >
                          Events
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#2"
                        >
                          Space users
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#3"
                        >
                          Rooms
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#4"
                        >
                          Claims
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#5"
                        >
                          Plans
                        </a>
                      </div>
                    ) : null}
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-primary ${
                        currentElementIndexInViewport !== 1 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#6')}
                    >
                      Incubation Management
                    </h4>
                    {currentElementIndexInViewport === 1 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#6"
                        >
                          Submissions
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#7"
                        >
                          One-on-one’s
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#8"
                        >
                          Assignments
                        </a>
                      </div>
                    ) : null}
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-primary ${
                        currentElementIndexInViewport !== 2 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#11')}
                    >
                      Community Management
                    </h4>
                    {currentElementIndexInViewport === 2 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#9"
                        >
                          Circles & Feed
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#10"
                        >
                          Messaging
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-black hover:text-primary"
                          href="#11"
                        >
                          People & startups
                        </a>
                      </div>
                    ) : null}
                  </div>
                </aside>
              </div>
              <div className="lg:col-span-1 lg:mx-8 col-span-2 space-y-24">
                <div ref={sectionRefs[0]} className="space-y-24">
                  <div className="space pt-20" id="1">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Events
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-primary">
                      Keep your members updated and engaged!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Bring your event to life with minimal effort. Your users can learn all about what you have to
                      offer through their dashboards and can sign up in just a few clicks. You can even create different
                      types of tickets and share your event with your network.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-2.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="2">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Space users
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Do you have an issue storing your member information? Not anymore! Check your user data, update
                      them, and assign plans all in one place. You can customize your membership plans, adjust the daily
                      and hourly access and the services offered with each one.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-1.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="3">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Rooms
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-primary">
                      A revolutionary and automatic room booking tool!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Experience a flexible room booking process that takes into account the price, the length of the
                      booking, and the user’s membership plan. Your members will be able to choose a meeting room based
                      on its availability and amenities through a built-in calendar.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-5.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="4">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Claims
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      You wanna report an issue? Njano displays this feature for users to communicate complaints related
                      to equipment, wifi, temperature,.. and many others for managers to fix them and track the solving
                      progress of each one.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-4.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="5">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Plans
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Enjoy the power of customizing unlimited plans for every type of membership your organization
                      offers. You can adjust the daily and hourly access and the services offered including the number
                      of free booking hours.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-14.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
                <div ref={sectionRefs[1]} className="space-y-24">
                  <div className="space pt-20" id="6">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Submissions
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-primary">
                      Njano spares you the effort of managing countless applications.
                    </p>
                    <p className="font-content mb-8 text-xl">
                      With our solution, you can easily scroll through your submissions, review them as well as validate
                      them for the next selection step.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-15.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="7">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      One-on-one’s
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-primary">
                      Discover a customizable interface to conduct your individual meetings!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Discover a customizable interface to conduct your individual meetings! You can set your slots, get
                      booked from your community and have your touchpoint hosted on Njano!
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-16.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="8">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Assignments
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-primary">
                      Managing your incubees&apos; livrables is now easier than ever!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Dive into a flexible feature that enables you to set, track and review assignments in simple
                      clicks.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-17.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
                <div ref={sectionRefs[2]} className="space-y-24">
                  <div className="space pt-20" id="9">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Circles & Feed
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Create or join circles that match your interests and get an overview about them all in your Feed!
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-11.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="10">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      Messaging
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Communicate privately with your colleagues and space users or connect with external Njano users
                      through a live chat system.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-12.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space pt-20" id="11">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6 text-primary">
                      People & startups
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Get to know more about your community members and build strong collaborations For a long-term
                      community bond.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-13.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Scrollspy>
      </div>
      <Community dark={false} />
      {showModal ? <Modal open={showModal} setOpen={setShowModal} /> : null}
    </Layout>
  );
};

export default IncubatorsPage;
